import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { toast } from "react-toastify";

const errorLink = onError(({ graphQLErrors }) => {
  console.log(graphQLErrors, "apollo error");
  if (graphQLErrors !== undefined) {
    toast.error(graphQLErrors?.[0]?.message);
    if (graphQLErrors?.[0]?.message === "Unauthorized") {
      localStorage.removeItem("token");
      if (window.location.pathname !== "/") {
        window.location.replace("/");
      }
    }
  }
});
console.log("Apollo intialized");
const link = from([
  errorLink,
  new HttpLink({ uri: process.env.GATSBY_API_URL }),
]);

const authLink = setContext((request, prev) => {
  try {
    let token = "";
    if (typeof localStorage !== "undefined") {
      token = localStorage?.getItem("token") ?? "";
    }
    return {
      headers: {
        authorization: token !== null ? `Bearer ${token}` : "",
      },
    };
  } catch (error) {
    console.log(error, "Apollo setcontext error");
  }
});

export const newLink = authLink.concat(link);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: newLink,
});

export default {};
