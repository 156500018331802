import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GET_USER_DETAILS_BY_ID } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";

// Define the interface for the context data
interface GlobalContextType {
  globalContextData: Record<string, any>;
  setGlobalContextData: Dispatch<SetStateAction<Record<string, any>>>;
}
const initialState = {
  globalContextData: {},
  setGlobalContextData: () => {},
};

const GlobalContext = createContext<GlobalContextType | undefined>(
  initialState,
);

interface GlobalContextProviderProps {
  children: React.ReactNode;
}
export const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({
  children,
}) => {
  const [globalContextData, setGlobalContextData] = useState<
    Record<string, any>
  >({});

  let id = "";

  if (typeof localStorage !== "undefined") {
    id = localStorage?.getItem("user_id") ?? "";
  }
  const [getUserdataQuery] = useLazyQuery(GET_USER_DETAILS_BY_ID);

  useEffect(() => {
    if (id?.length > 0) {
      if (!globalContextData?.userdata) {
        getUserdataQuery({
          variables: {
            id: Number(id),
          },
        }).then((res) => {
          setGlobalContextData((prev) => ({
            ...prev,
            userdata: res?.data?.getUserById,
          }));
        });
      }
    }
  }, []);
  return (
    <GlobalContext.Provider value={{ globalContextData, setGlobalContextData }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom hook to access the context value
export const useGlobalContext = (): GlobalContextType => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalContext must be used within a GlobalContextProvider",
    );
  }
  return context;
};
