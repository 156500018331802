import { gql } from "@apollo/client";

export const GET_ALL_AGENCIES = gql`
  query getAgency($page: Int!, $limit: Int!, $days: Int!) {
    getAgency(page: $page, limit: $limit, days: $days) {
      data {
        id
        agency_name
        email
        logo
        website
        linkedin
        added_from_admin
        rating
        phone
        min_budget
        description
        brand {
          id
          name
          industry {
            name
          }
          rating
          min_budget
        }
        location {
          country
          city
          state
        }
        updated_by {
          name
        }
        ips {
          ip
        }
        portfolio {
          portfolio_url
        }
        service {
          name
        }
      }
      meta {
        totalRecord
      }
    }
  }
`;

export const GET_ALL_MARKETER = gql`
  query getMarketer($page: Int!, $limit: Int!, $days: Int!) {
    getMarketer(page: $page, limit: $limit, days: $days) {
      data {
        id
        name
        email
        resume
        min_budget
        rating
        assigned_to {
          name
        }
        service {
          name
        }
        location {
          state
          country
          city
        }
        ips {
          ip
        }
        added_from_admin
        phone
        updated_by {
          name
        }
        ips {
          ip
        }
        portfolio {
          portfolio_url
        }
      }
      meta {
        totalRecord
      }
    }
  }
`;

export const GET_ALL_ONGOING = gql`
  query getAllproject($page: Int!, $limit: Int!, $days: Int!) {
    getAllproject(page: $page, limit: $limit, days: $days) {
      data {
        id
        brand_id {
          name
          email
          phone
          website
        }
        service {
          name
        }
        title
        created_by {
          name
        }
        createdAt
      }
    }
  }
`;

export const LOGIN_USER = gql`
  query loginUser($loginUserInput: LoginUserInput) {
    loginUser(loginUserInput: $loginUserInput) {
      token
      id
      success
      role_id {
        role
        id
      }
    }
  }
`;
export const GET_ALL_MEETINGS = gql`
  query getMeeting($page: Int!, $limit: Int!, $days: Int, $status: String) {
    getMeeting(page: $page, limit: $limit, days: $days, status: $status) {
      data {
        id
        status
        reason
        meeting_time
        lead_id {
          lead_type
          name
          website
          isWon
        }
        scheduled_by {
          name
        }
        status
      }
      meta {
        totalRecord
      }
    }
  }
`;

export const GET_ALL_BRANDS = gql`
  query getBrand($page: Int!, $limit: Int!, $days: Int!) {
    getBrand(page: $page, limit: $limit, days: $days) {
      data {
        id
        name
        email
        phone
        website
        product_description
        product_url
        product_name
        min_budget
        created_by {
          name
        }
        assigned_to {
          name
        }
        industry {
          name
        }
        poc {
          poc_name
          poc_email
          poc_phone
        }
        ips {
          ip
        }
        location {
          city
          country
          state
        }
        social_media {
          social_media_type
          channel_link
          user_name
          email
          password
          access_given
          createdAt
        }
      }
      meta {
        totalRecord
      }
    }
  }
`;
export const GET_BRAND_BY_ID = gql`
  query getBrandById($id: Int!) {
    getBrandById(id: $id) {
      id
      name
      email
      phone
      website
      product_description
      product_url
      product_name
      min_budget
      created_by {
        name
      }
      assigned_to {
        name
      }
      industry {
        name
      }
      poc {
        poc_name
        poc_email
        poc_phone
      }
      ips {
        ip
      }
      location {
        city
        country
        state
      }
      social_media {
        social_media_type
        channel_link
        user_name
        email
        password
        access_given
        createdAt
      }
    }
  }
`;
export const GET_ALL_BRANDS_NAME_ID = gql`
  query getBrandNameList {
    getBrandNameList {
      id
      name
    }
  }
`;

export const GET_ALL_LEADS = gql`
  query getLeads(
    $status: String
    $page: Int!
    $limit: Int!
    $days: Int
    $lead_type: String
  ) {
    getLeads(
      page: $page
      limit: $limit
      status: $status
      days: $days
      lead_type: $lead_type
    ) {
      data {
        name
        status
        id
        lead_type
        assigned_to {
          name
          id
        }
        createdAt
        assigned_by {
          name
          id
        }
        email
        phone
        company_strength
        poc_name
        poc_phone
        poc_email
        description
        website
        profit
        min_budget
        expense

        service {
          id
          name
        }
        industry {
          name
          id
        }
        created_by {
          name
          id
        }
        isWon
      }
      meta {
        totalRecord
      }
    }
  }
`;
export const GET_ALL_DASHBOARD_LEADS = gql`
  query getLeadOnDashboard(
    $status: String!
    $page: Int!
    $limit: Int!
    $days: Int!
  ) {
    getLeadOnDashboard(
      page: $page
      limit: $limit
      status: $status
      days: $days
    ) {
      data {
        name
        status
        id
        lead_type
        assigned_to {
          name
          id
        }
        createdAt
        assigned_by {
          name
          id
        }
        email
        phone
        company_strength
        poc_name
        poc_phone
        poc_email
        description
        website
        profit
        min_budget
        expense

        service {
          id
          name
        }
        industry {
          name
          id
        }
        created_by {
          name
          id
        }
        isWon
      }
      meta {
        totalRecord
      }
    }
  }
`;

export const GET_ALL_INDUSTRIES = gql`
  query getIndustry {
    getIndustry {
      name
      id
    }
  }
`;
export const GET_ALL_SERVICESS = gql`
  query getService {
    getService {
      name
      id
    }
  }
`;
export const GET_SOCIALMEDIA_TYPES = gql`
  query getService {
    getService {
      name
      id
    }
  }
`;

export const GET_AGENCIES_STATS = gql`
  query getAgencyStatusCount($days: Int!) {
    getAgencyStatusCount(days: $days) {
      activeAgency
      totalAgency
      ghostAgency
      deactiveAgency
      onboardedAgency
    }
  }
`;
export const GET_MARKETER_STATS = gql`
  query getMarketerStatusCount($days: Int!) {
    getMarketerStatusCount(days: $days) {
      activeMarketer
      totalMarketer
      ghostMarketer
      deactiveMarketer
      onboardedMarketer
    }
  }
`;

export const GET_BRANDS_STATS = gql`
  query getBrandStatusCount($days: Int!) {
    getBrandStatusCount(days: $days) {
      activeBrand
      totalBrand
      ghostBrand
      deactiveBrand
      onboardedBrand
    }
  }
`;
export const GET_DASHBOARD_STATS = gql`
  query getAllStats($days: Int) {
    getAllStats(days: $days) {
      onboardedAgency
      onboardedBrand
      onboardedMarketer
      totalBrand
      totalAgency
      totalMarketer
      totalLead
      ongoingCampaigns
    }
  }
`;

export const GET_ALL_STATS = gql`
  query getAllStats($days: Int) {
    getAllStats(days: $days) {
      activeAgency
      totalAgency
      totalLead
      onboardedAgency
      ongoingCampaigns
      activeMarketer
      onboardedMarketer
      totalMarketer
      onboardedAgency
      activeBrand
      totalBrand
      onboardedBrand
    }
  }
`;
export const GET_LEADS_STATS_DASHBOARD = gql`
  query getLeadCountByFilter($days: Int!) {
    getLeadCountByFilter(days: $days) {
      data {
        status
        count
      }
      previousData {
        status
        count
      }
    }
  }
`;
export const GET_ALL_ASSIGNED_USER = gql`
  query getAllAssignedUsers {
    getAllAssignedUsers {
      id
      name
      role_id {
        role
      }
      manager_id {
        id
        name
      }
    }
  }
`;

export const GET_ALL_CONVERTED = gql`
  query getConvertedLeadByFilter(
    $lead_type: String
    $page: Int
    $limit: Int
    $days: Int
  ) {
    getConvertedLeadByFilter(
      page: $page
      limit: $limit
      lead_type: $lead_type
      days: $days
    ) {
      data {
        name
        status
        id
        lead_type
        assigned_to {
          name
          id
        }
        createdAt
        assigned_by {
          name
        }
        email
        phone
        company_strength
        poc_name
        poc_phone
        poc_email
        description
        website
        profit
        min_budget
        expense
        service {
          name
        }
        industry {
          name
        }
        created_by {
          name
        }
        isWon
      }
      meta {
        totalRecord
      }
    }
  }
`;

export const GET_ALL_USER = gql`
  query getAllAssignedUsers {
    getAllAssignedUsers {
      id
      name
      email
      password
      phone
      isActive
      isDeleted
      role_id {
        id
        role
      }
      manager_id {
        id
        name
      }
    }
  }
`;

export const GET_ROLE = gql`
  query getRole {
    getRole {
      id
      role
    }
  }
`;

export const GET_ALL_USER_NEW = gql`
  query getAllAssignedUsers {
    getAllAssignedUsers {
      id
      name
    }
  }
`;

export const GET_PROJECT_BY_ID = gql`
  query getBrandProject($id: Int!, $limit: Int) {
    getBrandProject(id: $id, limit: $limit) {
      data {
        industry {
          name
        }
        service {
          name
        }
        brand_id {
          id
          industry {
            name
          }
          description
        }
        min_budget
        expense
        deadline
        profit
        title
      }
    }
  }
`;

export const GET_USER_DETAILS_BY_ID = gql`
  query getUserById($id: Int!) {
    getUserById(id: $id) {
      name
      role_id {
        role
      }
      manager_id {
        role_id {
          id
          role
        }
      }
    }
  }
`;
export default {};
