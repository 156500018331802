import "./src/styles/global.css";
import { ApolloProvider } from "@apollo/client";
import React from "react";
import { client } from "./src/configs/apolloClient";
import { ThemeProvider } from "@sourcebae/components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { GlobalContextProvider } from "./src/providers/GlobalContext";
export const wrapRootElement = ({ element }) => {
  const modifyTheme = (theme) => {
    return {
      ...theme,
      colours: {
        ...theme.colours,
      },
      palette: {
        primary: {
          ...theme.palette.primary,
          main: "#192023",
        },
        secondary: {
          ...theme.palette.secondary,
          main: "#B0D357",
        },
      },
      typography: {
        // fontFamily: var(--font-generalSans),
      },
    };
  };
  return (
    <>
    <ApolloProvider client={client}>
      <GlobalContextProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ToastContainer theme="dark" />
            <ThemeProvider modifyTheme={modifyTheme}>{element}</ThemeProvider>
        </LocalizationProvider>
        </GlobalContextProvider>
        </ApolloProvider>
        
    </>
  );
};
